import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from "moment";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function () {
        return {
            pageTitle: "Liste des navires",
            date_arriver: moment().format('DD/MM/YYYY'),
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            NAVIRE: [],
            vuetable: {
                moreParams: {},
                fields: [
                    {
                        name: 'date_arriver_formatted',
                        title: 'Date',
                        sortField: 'date_arriver_formatted',
                        dataClass: 'text-left',
                        width: '100px'
                    },
                    {
                        name: 'nom_navire',
                        title: 'Navire',
                        sortField: 'nom_navire',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'num_bl',
                        title: 'Numero BL',
                        sortField: 'num_bl',
                        dataClass: 'text-left',
                        width: '500px'
                    },

                    {
                        name: 'actions',
                        title: '-',
                        titleClass: 'text-center',
                        width: "80px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    {field: 'nom_navire', direction: 'asc'}
                ],
                css: {
                    table: {
                        // tableClass: 'table table-striped table-bordered table-hovered',
                        tableClass: 'table-responsive w-100 d-block d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            motCle: "",
            crudform: {
                id: '',
                nom_navire: '',
                etat_suppression: '',
                num_bl: [
                    {
                        id: '',
                        id_navire: '',
                        num_bl: '',
                        date_arriver: '',
                        date_arriver_formatted: moment().format('DD/MM/YYYY')
                    },
                ]
            },
            crudmodaltitle: "Nouveau Navire",

            btn_submit: false,
        }
    },
    methods: {

        spliceBL(index) {
            this.crudform.num_bl.splice(index, 1);
        },
        pushBL() {
            this.crudform.num_bl.push(
                {
                    id: '',
                    id_navire: '',
                    num_bl: '',
                    date_arriver: '',
                    date_arriver_formatted: moment().format('DD/MM/YYYY')
                },
            )
        },

        onRowClass(dataItem, index) {
            if (index == "") console.log("");

            if (Number(dataItem.solde) < 0) {
                // return 'text-danger text-bold';
            }
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        editRow(rowData) {
            let formulaire = new FormData();
            formulaire.append('date_arriver', rowData.date_arriver);
            formulaire.append('id_navire', rowData.id_navire);

            axios.post(this.BASE_URL + "/navire/get/",formulaire).then(response => {
                this.crudform = response.data[0];
                this.date_arriver = this.crudform.num_bl[0].date_arriver_formatted;
            });
            this.crudmodaltitle = "Editer le Navire";
            this.openModal();
        },

        deleteBL(bl, rowData){
            let formulaire = new FormData();
            formulaire.append('numero_bl', bl);
            formulaire.append('nom_navire', rowData.nom_navire);
            formulaire.append('date_arriver', rowData.date_arriver);
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer ce BL?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {

                        axios.post(this.BASE_URL + "/navire/deletebl/", formulaire).then( (response)=> {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                this.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })
        },

        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        let formulaire = new FormData();
                        formulaire.append('date_arriver', rowData.date_arriver);
                        formulaire.append('id_navire', rowData.id_navire);

                        axios.post(that.BASE_URL + "/ecoterme/delete/", formulaire).then(function (response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },

        openModal() {
            this.fetchData();
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Nouveau Navire";
            this.crudform = {
                id: '',
                nom_navire: '',
                etat_suppression: '',
                num_bl: [
                    {
                        id: '',
                        id_navire: '',
                        num_bl: '',
                        date_arriver: '',
                        date_arriver_formatted: moment().format('DD/MM/YYYY')
                    },
                ]
            };

            this.openModal();
        },
        onSubmit() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;

                        link = that.crudform.id == '' ? that.BASE_URL + "/navire/addaction" : that.BASE_URL + "/navire/editaction";

                        axios.post(link, $("#formulaire").serialize()).then(function (response) {
                            console.log(response);
                            that.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function () {
            axios.get(this.BASE_URL + "/navire/getall").then(response => {
                this.NAVIRE = response.data;
            });
        },
        gotoDossier(bl, data){
            console.log(data);
            let formulaire = new FormData();
            formulaire.append('num_bl',bl);
            formulaire.append('numero_navire',data.rowData.nom_navire);

            axios.post(this.BASE_URL+"/dossier/getinfobyblandnavire/",formulaire).then(response => {
                console.log(response.data);
                this.$router.push("/dossier/edit/"+response.data[0].id);
            })

            // this.$router.push("/dossier/edit/"+rowData.id);
        }
    },

    created: function () {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function () {

    }
}
